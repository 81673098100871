import React from 'react';
import styled from 'styled-components';

const AboutUsContainer = styled.div`
    position: relative;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    overflow: hidden;
`;

export const VideoBackground = styled.video`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -1;

    @media (max-width: 768px) {
        object-fit: fill; /* Adjust to fill the screen on smaller devices */
        height: auto;
    }

    @media (max-width: 480px) {
        object-fit: fill;
        height: auto;
    }
`;

const Content = styled.div`
    position: relative;
    z-index: 1;
    text-align: center;
`;

const Title = styled.h1`
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 1rem;
`;

const Text = styled.p`
    font-size: 1.5rem;
    max-width: 600px;
    margin: 0 auto;
    line-height: 1.5;
`;

const Aboutus = () => {
    return (
        <AboutUsContainer>
            <VideoBackground autoPlay loop muted>
                <source src={require('../../videos/aboutus.mp4')} type="video/mp4" />
                Your browser does not support the video tag.
            </VideoBackground>
          
        </AboutUsContainer>
    );
};

export default Aboutus;

import React from 'react';
import { Button } from '../../globalStyles';
import { AiFillThunderbolt } from 'react-icons/ai';
import { GiCrystalBars } from 'react-icons/gi';
import { GiCutDiamond, GiRock } from 'react-icons/gi';
import { GiFloatingCrystal } from 'react-icons/gi';

// import icons
import { GiChart, GiDatabase, GiMagnifyingGlass , GiShoppingCart, GiCalendar, GiCloudDownload, GiChatBubble , GiPieChart      } from 'react-icons/gi';

import { IconContext } from 'react-icons/lib';
import {
  KeyvalueSection,
  KeyvalueWrapper,
  KeyvalueHeading,
  KeyvalueContainer,
  KeyvalueCard,
  KeyvalueCardInfo,
  KeyvalueCardIcon,
  KeyvalueCardPlan,
  KeyvalueCardCost,
  KeyvalueCardLength,
  KeyvalueCardFeatures,
  KeyvalueCardFeature
} from './Keyvalue.elements';

 const Keyvalue = () => {
  return (
    <IconContext.Provider value={{ color: '#a9b3c1', size: 64 }}>
      <KeyvalueSection>
        <KeyvalueWrapper>
          <KeyvalueHeading>Key Value Proposition</KeyvalueHeading>
          <KeyvalueContainer>
            <KeyvalueCard to='/sign-up'>
              <KeyvalueCardInfo>
                <KeyvalueCardIcon>
                  <GiCalendar  />
                </KeyvalueCardIcon>
                <KeyvalueCardPlan>Three-Months Contracts</KeyvalueCardPlan>
                {/* <KeyvalueCardCost>$99.99</KeyvalueCardCost>
                <KeyvalueCardLength>per month</KeyvalueCardLength> */}
                <KeyvalueCardFeatures>
                  <KeyvalueCardFeature>One month of setting up and two months of maintaining. If you are happy with our service we can extend our service monthly.</KeyvalueCardFeature>
                  
                </KeyvalueCardFeatures>
                {/* <Button primary>Choose Plan</Button> */}
              </KeyvalueCardInfo>
            </KeyvalueCard>
            <KeyvalueCard to='/sign-up'>
              <KeyvalueCardInfo>
                <KeyvalueCardIcon>
                  <GiCloudDownload  />
                </KeyvalueCardIcon>
                <KeyvalueCardPlan>Automated Cloud Report</KeyvalueCardPlan>
                {/* <KeyvalueCardCost>$99.99</KeyvalueCardCost>
                <KeyvalueCardLength>per month</KeyvalueCardLength> */}
                <KeyvalueCardFeatures>
                  <KeyvalueCardFeature>We build automated cloud reports that can view on any of your devices.</KeyvalueCardFeature>
                  
                </KeyvalueCardFeatures>
                {/* <Button primary>Choose Plan</Button> */}
              </KeyvalueCardInfo>
            </KeyvalueCard>
            <KeyvalueCard to='/sign-up'>
              <KeyvalueCardInfo>
                <KeyvalueCardIcon>
                  <GiChatBubble  />
                </KeyvalueCardIcon>
                <KeyvalueCardPlan>Single Contract Point</KeyvalueCardPlan>
                {/* <KeyvalueCardCost>$99.99</KeyvalueCardCost>
                <KeyvalueCardLength>per month</KeyvalueCardLength> */}
                <KeyvalueCardFeatures>
                  <KeyvalueCardFeature> You will have direct access to a specialist and a faster response time.</KeyvalueCardFeature>                
                </KeyvalueCardFeatures>
                {/* <Button primary>Choose Plan</Button> */}
              </KeyvalueCardInfo>
            </KeyvalueCard>
            <KeyvalueCard to='/sign-up'>
              <KeyvalueCardInfo>
                <KeyvalueCardIcon>
                  <GiPieChart  />
                </KeyvalueCardIcon>
                <KeyvalueCardPlan>KPI Focused</KeyvalueCardPlan>
                {/* <KeyvalueCardCost>$99.99</KeyvalueCardCost>
                <KeyvalueCardLength>per month</KeyvalueCardLength> */}
                <KeyvalueCardFeatures>
                  <KeyvalueCardFeature>We focus on 1 or 2 KPIs that matters to you</KeyvalueCardFeature>
                  
                </KeyvalueCardFeatures>
                {/* <Button primary>Choose Plan</Button> */}
              </KeyvalueCardInfo>
            </KeyvalueCard>
          </KeyvalueContainer>
        </KeyvalueWrapper>
      </KeyvalueSection>
    </IconContext.Provider>
  );
}

export default Keyvalue
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import the AOS CSS
import { InfoSection, Pricing, Keyvalue, FAQSection, Testimonial } from '../../components';
import { homeObjThree } from './Data';

const Home = () => {
    useEffect(() => {
        AOS.init({
            duration: 1200,  // Duration of the animation
            once: true,      // Whether animation should happen only once - while scrolling down
        });
    }, []);

    return (
        <>
            <InfoSection {...homeObjThree} data-aos="fade-up" />
            <Pricing data-aos="fade-up" />
            <Keyvalue data-aos="fade-up" />
            <Testimonial data-aos="fade-up" />
            <FAQSection data-aos="fade-up" />
        </>
    );
}

export default Home;

import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const KeyvalueSection = styled.div`
  padding: 100px 0 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
`;

export const KeyvalueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  @media screen and (max-width: 960px) {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const KeyvalueHeading = styled.h1`
  color: #121212;
  font-size: 48px;
  margin-bottom: 24px;
`;

export const KeyvalueContainer = styled.div`
  display: flex;
  justify-content: center; // Center cards horizontally
  align-items: center;
  flex-wrap: wrap; // Ensure wrapping is enabled
  gap: 24px; // Space between cards
  max-width: 100%; // Ensure the container can expand to fit two cards
  margin: auto; // Center the container on the page

  @media screen and (max-width: 960px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;




export const KeyvalueCard = styled(Link)`
  background: #242424;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
  width: calc(40% - 30px); // Ensure this calculation allows two cards per row including margins
  height: 280px;
  text-decoration: none;
  border-radius: 50px;
  margin: 15px; // You may adjust the margin if necessary to fit two cards

  &:hover {
    transform: scale(1.06);
    transition: all 0.3s ease-out;
    color: #1c2237;
  }

  @media screen and (max-width: 960px) {
    width: 90%; // Full width on smaller screens
    margin: 12px auto; // Centered margin
    &:hover {
      transform: none;
    }
  }
`;

export const KeyvalueCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 24px;
  align-items: center;
  color: #fff;
`;

export const KeyvalueCardIcon = styled.div`
  margin:4px 0;
`;

export const KeyvalueCardPlan = styled.h3`
  color: #fff;
  margin-bottom: 0px;
  font-size: 24px;
`;

export const KeyvalueCardCost = styled.h4`
  font-size: 20px;
`;

export const KeyvalueCardLength = styled.p`
  font-size: 14px;
  margin-bottom: 2px;
`;

export const KeyvalueCardFeatures = styled.ul`
  margin: 16px 0 32px;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a9b3c1;
`;

export const KeyvalueCardFeature = styled.li`
  margin-bottom: 10px;
`;
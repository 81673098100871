import styled from 'styled-components';

export const TestimonialSection = styled.div`
  padding: 100px 0 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
  font-family: 'Roboto', sans-serif;
`;

export const TestimonialWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 40%;
  max-width: 1200px;
   @media screen and (max-width: 960px) {
    margin: 0 20px;
    width: 90%;  // Adjusted width for better responsiveness
`;

export const TestimonialHeading = styled.h1`
  color: #333;
  font-size: 48px;
  margin-bottom: 24px;
  text-align: center;
  font-family: 'Playfair Display', serif;
   @media screen and (max-width: 768px) {
    font-size: 28px;  // Further reduction for very small screens
  }
`;

export const TestimonialContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 1000px;  /* Increased width */
  height: 600px;  /* Increased height */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 50px;
  align-items: center;

  @media screen and (max-width: 768px) {
    padding: 10px;
  }
`;

export const StyledTestimonial = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 50px;
  padding: 50px;
  box-sizing: border-box;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  border: 5px solid #f0f0f0;
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 1s ease, transform 1s ease;
  
  &.active {
    opacity: 1;
    transform: scale(1);
  }

  @media screen and (max-width: 768px) {
    padding: 20px;
    width: 100%;
  }
`;

export const TestimonialText = styled.p`
  font-size: 20px;
  margin-bottom: 20px;
  color: #555;
  text-align: center;
  padding: 0 40px;
  line-height: 1.6;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    padding: 0 10px;
  }
`;

export const TestimonialAuthor = styled.h4`
  font-size: 18px;
  color: #777;
  margin-top: 20px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

export const TestimonialImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
  border: 3px solid #ddd;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
`;

import React from 'react';
import { Link } from 'react-router-dom';
import { blogObjOne, blogObjTwo, blogObjThree } from './Data';

function Blogs() {
    const blogs = [blogObjOne, blogObjTwo, blogObjThree];

    return (
        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            {blogs.map((blog) => (
                <Link to={`/blog/${blog.id}`} style={blog.style.container} key={blog.id}>
                    <img src={blog.imageUrl} alt={blog.title} style={blog.style.image}/>
                    <h2 style={blog.style.title}>{blog.title}</h2>
                    <p style={blog.style.author}>{blog.author}</p> {/* New line added for author */}
                    <p style={blog.style.date}>{blog.date}</p> {/* Date */}
                    <p style={blog.style.summary}>{blog.summary}</p>
                </Link>
            ))}
        </div>
    );
}

export default Blogs;

import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { FaPlus, FaMinus } from 'react-icons/fa'; // Import icons

// Styled Components
const FAQContainer = styled.div`
  padding: 20px;
  background: transparent; // Set background to transparent

  margin: 100px auto;
  font-family: 'Roboto', sans-serif;
  max-width: 1200px; // Ensure the container width is reasonable
  
  
  display: flex; // Ensure content is laid out properly
  flex-direction: column; // Column layout for title and content
`;

const Title = styled.h1`
  text-align: center;
  color: #000;
  font-size: 2.5em;
  font-weight: 600;
  margin-bottom: 20px;
`;

const QuestionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); // Two columns
  grid-column-gap: 20px; // Keep the existing column gap
  grid-row-gap: 0px; // Reduce the row gap to your desired size
  width: 100%; // Ensure grid takes full width of the container
  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr; // Make it one column on smaller screens
  }
`;


const QuestionWrapper = styled.div`
  margin-bottom: 0px; // Space below each question block
`;

const Question = styled.div`
  padding: 15px;
  margin: 10px 0;
  background: #ffffff;
  color: #333;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
  box-shadow: 0 2px 15px rgba(0,0,0,0.1);
  transition: background-color 0.3s;

  &:hover {
    background-color: #f7f7f7;
  }
`;

const Answer = styled.div`
  padding: 0 15px;
  margin-bottom: 10px;
  background: #f9f9f9;
  border-left: 25px solid #6E7A88;
  border-radius: 15px;
  color: #333;
  overflow: hidden;
  transition: max-height 0.5s ease, padding 0.3s ease-in-out;
  transition-property: ${props => props.expand ? 'max-height, padding' : 'none'}; // Apply transitions only when expanding
  max-height: 0;

  ${props => props.expand && css`
    padding: 15px;
    max-height: 400px; // Use a large number if content height is variable
  `}
`;

const ToggleButton = styled.button`
  background: transparent;
  border: none;
  color: #303030;
  font-size: 1.5em;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

// Component Definition
const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const questionsAndAnswers = [
    { question: "Why wouldn’t I hire a marketing/social media person in-house?",
      answer: "Hiring an in-house marketing/social media professional can be quite expensive. The average salary for a marketing manager in the US is around $65,000 to $100,000 per year. Additionally, you need to consider the costs of handling taxes, social security, insurance, and other employee benefits, which can add another 20-30% to the base salary. By partnering with Pizen, you gain the benefits of a long-term relationship similar to hiring in-house, but with the added advantage of accessing a team of skilled marketing and social media experts, all through a single, cost-effective partnership." },
    { question: "What are the benefits of hiring a marketing agency?",
      answer: "When you hire a marketing manager, you gain the expertise of one individual with knowledge in a few areas. However, hiring a marketing agency like Pizen provides you with a comprehensive team that includes a copywriter, a strategist, a social media manager, and an online advertising specialist, ensuring all aspects of your marketing needs are expertly handled." },
    { question: "What is Pizen’s uniqueness compared to other agencies?",
      answer: "Pizen offers an initial 3 month contract to get the ball rolling and then we continue on a monthly basis. Our in-house industry analysts can quickly grasp most traditional and technical industries, ensuring thorough market research to provide accurate segmentation for your business, helping you understand your sector and target your ads effectively. Additionally, we offer both single and bundled services, allowing small SMEs to outsource their entire marketing needs or larger firms to select specific services as needed." },
    { question: "What type of clients do you work with?",
      answer: "We work with all types of clients, from individuals seeking to grow their personal brand to small and large enterprises from various industries. What our clients have in common is the desire to grow their business online. We enjoy working with teams that have a growth mindset and take a long term view on partnering with us!" },
    { question: "I am a small business looking to get started on marketing. Where do I start?",
      answer: "We suggest starting with one or two core services that directly impact your revenue, rather than opting for extensive and costly solutions. This can be as simple as setting up a proper sales funnel with targeted ads, content marketing, and social media management. We'll track performance and adjust our strategy based on the results, ensuring your marketing efforts are both effective and scalable." },
    { question: "I am a medium to large-size organisation looking to outsource certain services. Can that be done?",
      answer: "Yes, it can be done. In large-sized organizations, there's often limited capacity to handle all marketing and social media aspects, and that's where we come in. We have a proven track record of partnering with clients for years, seamlessly integrating into their operations and easing their workload while delivering expert marketing solutions." },
  ];


  const toggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <FAQContainer>
      <Title>F A Q</Title>  {}
      <QuestionsGrid>
        {questionsAndAnswers.map((item, index) => (
          <QuestionWrapper key={index}>
            <Question onClick={() => toggle(index)}>
              {item.question}
              <ToggleButton>{activeIndex === index ? <FaMinus /> : <FaPlus />}</ToggleButton>
            </Question>
            <Answer expand={activeIndex === index}>{item.answer}</Answer>
          </QuestionWrapper>
        ))}
      </QuestionsGrid>
    </FAQContainer>
  );
};

export default FAQSection;

export const homeObjOne = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Content Creation',
    headline: 'Elevate Your Brand with Expert Content Creation',
    description:
      'We\'ve helped countless businesses like yours achieve their marketing goals through strategic content creation',
    buttonLabel: 'Get Started',
    // imgStart: '',
    // img: require('../../images/svg-1.svg'),
    // alt: 'Credit Card',
    start: ''
  };
  
  export const homeObjTwo = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Instant Setup',
    headline: 'Extremely quick onboarding process',
    description:
      "Once you've joined, our team of specialist will reach out to you and get you set up in minutes.",
    buttonLabel: 'Learn More',
    // imgStart: '',
    // img: require('../../images/svg-2.svg'),
    // alt: 'Vault',
    start: ''
  };
  
  export const homeObjThree = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    topLine: 'An Asia-Pacific Digital Marketing Agency',
    headlines: [
        'We Grow Leads',
        'We Boost Sales',
        'We Engage Audiences',
        'We Expand Reach',
        'We Build Brands'
    ],
    description:
        "We elevate growing businesses with tailored, measurable results through a complete suite of integrated marketing solutions—partnering with you every step of the way.",
    buttonLabel: 'Contact Us',
    imgStart: 'start',
    video: require('../../videos/top_page.mp4'), // Add this line with the correct path to your video
    alt: 'Marketing Video',
    start: 'true'
};

  
  
  export const homeObjFour = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Secure Database',
    headline: 'All your data is stored on our secure server',
    description:
      'You will never have to worry about your information getting leaked. Our team of security experts will ensure your records are kept safe.',
    buttonLabel: 'Sign Up Now',
    // imgStart: 'start',
    // img: require('../../images/svg-3.svg'),
    // alt: 'Vault',
    start: 'true'
  };
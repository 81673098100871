import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import GlobalStyles from './globalStyles';
import { Navbar, Footer } from './components';
import Home from './pages/HomePage/Home';
import Aboutus from './pages/Aboutus/Aboutus';
import Blogs from './pages/Blogs/Blogs';
import BlogDetail from './pages/Blogs/BlogDetail'; // Corrected import
import SignUp from './pages/SignUp/SignUp';
import ScrollToTop from './components/ScrollToTop';


function App() {
  return (
    <Router>
        <GlobalStyles />
        <ScrollToTop />
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/aboutus' component={Aboutus} />
          <Route path="/blogs" exact component={Blogs} />
          <Route path="/blog/:blogId" component={BlogDetail} /> // Corrected path for blog details
          <Route path='/sign-up' component={SignUp} />
        </Switch>
        <Footer />
    </Router>
  );
}

export default App;

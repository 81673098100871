import React from 'react';
import { useParams } from 'react-router-dom';
import { blogObjOne, blogObjTwo, blogObjThree } from './Data';
import './BlogDetail.css';
import DOMPurify from 'dompurify';

const blogs = {
  blog1: blogObjOne,
  blog2: blogObjTwo,
  blog3: blogObjThree,
};

function BlogDetail() {
  const { blogId } = useParams();
  const blog = blogs[blogId];

  const createMarkup = (htmlContent) => {
    return { __html: DOMPurify.sanitize(htmlContent) };
  };

  return (
    <div className="blog-container">
      <img src={blog.imageUrl} alt={blog.title} className="blog-image" />
      <div className="blog-content">
        <h1 className="blog-title">{blog.title}</h1>
        <p className="blog-date-author">{blog.date} by {blog.author}</p>
        <div className="blog-text" dangerouslySetInnerHTML={createMarkup(blog.fullText)}></div>
      </div>
    </div>
  );
}

export default BlogDetail;

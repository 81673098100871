import React, { useState, useEffect } from 'react';
import { InfoSec, InfoRow, InfoColumn, TextWrapper, TopLine, Heading, Subtitle, ImgWrapper, Img } from './InfoSection.elements';
import { Container, Button } from '../../globalStyles';
import { Link } from 'react-router-dom';
import './Typewriter.css';

const Typewriter = ({ headlines }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [loop, setLoop] = useState(0);

  useEffect(() => {
    const currentHeadline = headlines[currentIndex];
    const speed = isDeleting ? 75 : 150;

    const timer = setTimeout(() => {
      setDisplayedText((prev) =>
        isDeleting
          ? currentHeadline.substring(0, prev.length - 1)
          : currentHeadline.substring(0, prev.length + 1)
      );

      if (!isDeleting && displayedText === currentHeadline) {
        setTimeout(() => setIsDeleting(true), 1000);
      } else if (isDeleting && displayedText === '') {
        setIsDeleting(false);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % headlines.length);
        setLoop(loop + 1);
      }
    }, speed);

    return () => clearTimeout(timer);
  }, [displayedText, isDeleting]);

  return (
    <Heading className={isDeleting && displayedText === '' ? 'empty' : ''}>
      {displayedText}
      {/* use a non-breaking space (&nbsp;) in the span. This character is invisible but ensures the span element stays in the DOM and is rendered. */}
      <span className="cursor">&nbsp;</span> 
    </Heading>
  );
};

  

const InfoSection = ({ 
    primary,
    lightBg,
    topLine,
    lightTopLine,
    lightText,
    lightTextDesc,
    headlines,
    description,
    buttonLabel,
    video, // Add the video prop
    img, // Keep the image prop in case you need to use it elsewhere
    alt,
    imgStart,
    start
}) => {
    return (
        <>
            <InfoSec lightBg={lightBg}>
                <Container>
                    <InfoRow imgStart={imgStart}>
                        <InfoColumn>
                            <TextWrapper>
                                <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
                                <Typewriter headlines={headlines} />
                                <Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle>
                                <Link to='/sign-up'>
                                    <Button big fontBig primary={primary}>
                                        {buttonLabel}
                                    </Button>
                                </Link>
                            </TextWrapper>
                        </InfoColumn>
                        <InfoColumn>
                            <ImgWrapper start={start}>
                                {video ? (
                                    <video 
                                        autoPlay 
                                        loop 
                                        muted 
                                        src={video} 
                                        alt={alt} 
                                        style={{ maxWidth: '100%', maxHeight: '700px' }} // Adjust the size here
                                    />
                                ) : (
                                    <Img src={img} alt={alt} />
                                )}
                            </ImgWrapper>
                        </InfoColumn>
                    </InfoRow>
                </Container>
            </InfoSec>
        </>
    );
};



export default InfoSection;

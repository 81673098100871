import React, { useEffect, useState } from 'react';
import {
  TestimonialSection,
  TestimonialWrapper,
  TestimonialHeading,
  TestimonialContainer,
  StyledTestimonial,
  TestimonialText,
  TestimonialAuthor,
  TestimonialImage,
} from './Testimonial.elements';

// Import images
import IsanRealEstateImage from '../../images/Isan Real Estate_logo.jpg';
import CodeGenixLogo from '../../images/codegenix_logo.jpg';  
import sparkleclean_logo from '../../images/sparkleclean_logo.jpg'; 

const myTestimonials = [
  {
    text: 'Partnering with Pizen has greatly enhanced our social media presence at Isan Real Estate. Their expert management and engaging content have attracted more clients, while their performance marketing dashboards provided clear, actionable insights. We now make data-driven decisions that optimize our campaigns effectively. Pizen\'s professionalism and expertise were invaluable.',
    author: 'Somchai P., Managing Director of Isan Real Estate',
    image: IsanRealEstateImage  // Use the imported image
  },
  {
    text: 'Working with Pizen has significantly boosted our online presence! Their strategic social media campaigns and custom performance dashboards have driven increased engagement and client inquiries. Pizen\'s professionalism and industry knowledge make them an invaluable partner. Highly recommended.',
    author: 'Alex T., CEO of CodeGenix',
    image: CodeGenixLogo  // Use the imported image
  },
  {
    text: 'Partnering with Pizen has been fantastic for our client engagement and lead generation. Their performance marketing dashboards provided clear insights, helping us optimize our campaigns and boost client engagement. Highly recommend their expertise!',
    author: 'Emily R., CEO of SparkleClean Solutions',
    image: sparkleclean_logo  // Use the imported image
  }
];

const Testimonial = () => {
  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTestimonialIndex((prevIndex) => (prevIndex + 1) % myTestimonials.length);
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  return (
    <TestimonialSection>
      <TestimonialWrapper>
        <TestimonialHeading>Client Testimonials</TestimonialHeading>
        <TestimonialContainer>
          {myTestimonials.map((testimonial, index) => (
            <StyledTestimonial key={index} className={index === currentTestimonialIndex ? 'active' : ''}>
              <TestimonialImage src={testimonial.image} alt={testimonial.author} />
              <TestimonialText>"{testimonial.text}"</TestimonialText>
              <TestimonialAuthor>- {testimonial.author}</TestimonialAuthor>
            </StyledTestimonial>
          ))}
        </TestimonialContainer>
      </TestimonialWrapper>
    </TestimonialSection>
  );
};

export default Testimonial;

import React, { useState } from 'react';
import './SignUp.css'; // Import the CSS styles

function SignUp() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    services: [],
    budget: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleServicesChange = (e) => {
    const { value, checked } = e.target;
    setFormData(prevState => {
      let services = [...prevState.services];
      if (checked) {
        // Add only if it's not already added
        if (!services.includes(value)) {
          services.push(value);
        }
      } else {
        // Remove the service when unchecked
        services = services.filter(service => service !== value);
      }
      return { ...prevState, services };
    });
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Create a single string from the services array
    const servicesString = formData.services.join(',');
  
    // Check if the hidden input already exists
    let hiddenInput = document.querySelector('input[name="services"]');
    if (!hiddenInput) {
      // Create it if it doesn't exist
      hiddenInput = document.createElement('input');
      hiddenInput.type = 'hidden';
      hiddenInput.name = 'services';
      e.target.appendChild(hiddenInput);
    }
    // Update the value of the hidden input
    hiddenInput.value = servicesString;
  
    // Now submit the form
    e.target.submit();
  };
  

  return (
    <div className="formContainer">
      <div className="formSection">
        <div className='contact-title'></div>
        <h2 className="title">Let us get back to you!</h2>
        <p className="title-text">We promise to respond within 24 hours.</p>
        <form onSubmit={handleSubmit} action="https://api.web3forms.com/submit" method="POST">  
          <div className="fieldContainer">
            <input type="hidden" name="access_key" value="ce08bb98-caf1-40a7-92e3-5217d12a6554"/>
            <input type="text" name="name" value={formData.name} onChange={handleChange} required placeholder="Name" className="input" />
            <input type="email" name="email" value={formData.email} onChange={handleChange} required placeholder="Email" className="input" />
            <textarea name="message" value={formData.message} onChange={handleChange} placeholder="Write your message here (Optional)" className="input"></textarea>
            <label>What services are you interested in?</label>
            {["Content Marketing", "Social Media Management", "Email Automation", "SEO", "Performance Marketing", "Analytics"].map(service => (
              <div key={service}>
                <input type="checkbox" value={service} checked={formData.services.includes(service)} onChange={handleServicesChange} />
                {service}
              </div>
            ))}
          </div>
          <button type="submit" className="submitButton">Send</button>
        </form>
      </div>
      <div className="imageSection">
        <img src='/assets/form_img.svg' alt='Service Visualization' />
      </div>
    </div>
  );
}

export default SignUp;
